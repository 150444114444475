import React from 'react';
import './App.css';
import RSSFeed from './containers/RSSFeed'

function App() {
  let lang = 'fi';
  const language = navigator.language || navigator.browserLanguage;
  if (language.includes('en') || language.includes('us')) {
    lang='us';
  } else if (language.includes('se')) {
    lang='se';
  }
  return (
    <RSSFeed lang={lang}></RSSFeed>
  );
}

export default App;

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTh, faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import ReactCountryFlag from "react-country-flag";
import flag from '../flag.svg'
import styled from 'styled-components'
import { getFeedListing } from "../requests"
import rss_fi from '../data/rss_fi.json'
import rss_us from '../data/rss_us.json'
import rss_se from '../data/rss_se.json'
import moment from 'moment'

const Body = styled.div`
  width: 100vw;
  background: #003580;
  background-attachment: fixed;
  padding: 5px 0px;
  padding-bottom: 0px;
`

const Header = styled.div`
  width: 96vw;
  margin: 0px auto;
  margin: 0px auto 5px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Grenze', serif;
  font-size: 1.3em;
  font-weight: 500;
  padding: 5px 0px;
  letter-spacing: 2px;
  flex-direction: row;
  line-height: 1em;
  color: white;
  text-align: center;

  @media (max-width: 1000px) {
    font-size: 1em;
  }

  @media (max-width: 660px) {
    font-size: .8em;
  }

  @media (max-width: 850px) {
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    width: 100vw;
    justify-content: center;
  }
`

const Title = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;

  @media (max-width: 850px) {
    position: absolute;
    padding-top: 40px;
  }
`

const Email = styled.div`
  font-weight: 400;
  position: absolute;
  width: 98%;
  padding-right: 2vw;
  text-align: right;
  font-size: .8em;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 850px) {
    position: relative;
    width: 50%;
  }

  @media (max-width: 440px) {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 10px;
  }
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  font-size: .8em;
  position: absolute;
  width: 96vw;
  margin-left: 2vw;
  z-index: 999;

  @media (max-width: 850px) {
    position: relative;
    width: 50%;
  }
`

const Flag = styled.div`
  background-image: url('${flag}');
  width: 50px;
  height: 50px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;

  @media (max-width: 850px) {
    width: 25px;
    height: 25px;
  }
`

const Container = styled.div`
  width: 100vw;
  background: #f5f5f5;
  margin: 0px auto;
  padding: 10px 1vw 40px 1vw;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center;

  @media (max-width: 400px) {
    padding-top: 5px;
  }
`

const RSS = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-left: 20px;
`

const ReactCountryFlagStyled = styled(ReactCountryFlag)`
  cursor: pointer;
  padding: 0px 5px;
`

const NewsItem = styled.div`
  width: calc(100% - 20px);
  margin: 10px auto;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 1px;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 100ms ease-in-out;

  :hover {
    box-shadow: 0px 0px 4px 0px #003580;
  }

  @media (max-width: 800px) {
    padding: 10px;
  }
`

const NewsItemSingle = styled.div`
  width: 25%;

  @media (max-width: 800px) {
    width: 33%;
  }

  @media (max-width: 600px) {
    width: 49%;
  }

  @media (max-width: 400px) {
    width: 99%;
  }
`

const NewsTitle = styled.div`
  font-size: .9em;
`

const BottomBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 0.6em;
  padding-top: 10px;
  color: #777;

  @media (max-width: 800px) {
    padding-top: 10px;
  }
`

const DateText = styled.div`

`

const SourceName = styled.div`

`

const Loading = styled.div`
  height: 100vh;
  text-align: center;
  margin: 0px auto;
`

const NewsSource = styled.div`
  width: 25%;
  margin: 10px 0;

  @media (max-width: 800px) {
    width: 33%;
  }

  @media (max-width: 600px) {
    width: 49%;
  }

  @media (max-width: 400px) {
    width: 99%;
  }
`

const NewsSourceTitle = styled.div`
  font-weight: 500;
  text-align: center;

  & :hover {
    color: #003580;
  }
`

const Flags = styled.div`
  display: flex;
  z-index: 9999;
  padding-right: 20px;
  justify-content: flex-end;
`

const Info = styled.div`
  width: 50vw;
  position: absolute;
  background: #003580;
  border-radius: 16px;
  padding: 40px;
  color: white;

  @media (max-width: 600px) {
    width: 90vw;
    padding: 20px 10px;
  }
`

const DashLine = styled.div`
  width: 100%;
  border-bottom: 2px dashed;
  margin: 20px 0px;
`

const CloseBtn = styled.div`
  float: right;
  margin-top: -30px;

  @media (max-width: 600px) {
    margin-top: -15px;
  }
`

const InfoCredits = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`

class RSSFeed extends React.Component {

  state = {
    news: [],
    sortedNews: [],
    country: this.props.lang,
    gridByDate: true,
    info: false
  }

  getNews = async rss_fi => {
    let array = [];
    if (this.state.country === "us") {
      rss_fi = rss_us;
    } else if (this.state.country === "se") {
      rss_fi = rss_se;
    }
    for (const source of Object.keys(rss_fi)) {
      const o = {
        name: null,
        url: []
      };
      try {
        const response = await getFeedListing(rss_fi[source]["url"]);
        if (response.status === 200) {
          o.name = rss_fi[source]["name"];
          o.link = rss_fi[source]["link"];
          const reduce = response.data.items;
          // reduce.length = 5;
          o.url = reduce;
          array.push(o);
        }
      } catch (ex) {
        // console.log(ex);
      }
    }
    return array;

  }

  async componentDidMount() {
    const news = await this.getNews(rss_fi);
    let sortedNews = [];
    for (const source of news) {
      for (const url of source.url) {
        url.name = source.name;
        url.href = source.link;
        sortedNews.push(url);
      }
    }
    sortedNews.sort(function(a,b) {
      return moment(b.pubDate).valueOf() - moment(a.pubDate).valueOf();
    });
    this.setState({sortedNews: sortedNews, news: news});
  }

  async updateNews() {
    const news = await this.getNews(rss_fi);
    let sortedNews = [];
    for (const source of news) {
      for (const url of source.url) {
        url.name = source.name;
        url.href = source.link;
        sortedNews.push(url);
      }
    }
    sortedNews.sort(function(a,b) {
      return new Date(b.pubDate) - new Date(a.pubDate);
    });
    this.setState({sortedNews: sortedNews, news: news});
  }

  formatDate(date) {

    let diff = moment().valueOf() - moment(date).valueOf();
  
    if (diff < 1000) { // less than 1 second
      return 'juuri nyt';
    }
  
    let sec = Math.floor(diff / 1000); // convert diff to seconds
  
    if (sec < 60) {
      return sec + 's';
    }
  
    let min = Math.floor(diff / 60000); // convert diff to minutes
    if (min < 60) {
      return min + 'min';
    }

    let h = Math.floor(diff / 3600000); // convert diff to minutes
    if (h < 24) {
      return h + 'h';
    }

    return moment(date).format("DD.MM.YYYY HH:mm");
  }

  changeGrid = () => {
    this.setState({gridByDate: !this.state.gridByDate});
  }

  changeCountry = (country) => {
    this.setState({country: country}, () => this.updateNews());
  }

  toggleInfo = () => {
    this.setState({info: !this.state.info});
  }

  render() {
    const header = (
      <Header>
        <Logo>
          <Flag/>KMedia.fi
          <FontAwesomeIconStyled icon={faTh} title="Change grid" onClick={() => this.changeGrid()} />
          <FontAwesomeIconStyled icon={faInfoCircle} title="Info" onClick={() => this.toggleInfo()} />
        </Logo>
        <Email>
          <Flags>
            <div onClick={() => this.changeCountry("fi")}>
              <ReactCountryFlagStyled code="fi" title="News of Finland" svg />
            </div>
            <div onClick={() => this.changeCountry("se")}>
              <ReactCountryFlagStyled code="se" title="News of Sweden" svg />
            </div>
            <div onClick={() => this.changeCountry("us")}>
              <ReactCountryFlagStyled code="us" title="News of USA" svg />
            </div>
          </Flags>
          <a href="mailto:kmedia@kmedia.fi">kmedia@kmedia.fi</a>
          </Email>
        <Title>Vastapaino punavihreälle komedialle</Title>
      </Header>
    );
    if (this.state.gridByDate && this.state.sortedNews.length > 0) {
      let newsArray = [];
      for (const item of this.state.sortedNews) {
        const date = this.formatDate(item.pubDate);
        const newsItem = (
          <NewsItemSingle key={item.link}>
            <a href={item.link} target="_blank" rel="noopener noreferrer">  
              <NewsItem>
                <NewsTitle>{item.title}</NewsTitle>
                <BottomBar>
                  <SourceName>{item.name}</SourceName>
                  <DateText>{date}</DateText>
                </BottomBar>
              </NewsItem>
            </a>
          </NewsItemSingle>
        );
        newsArray.push(newsItem);
      }
      return (
        <Body>
          {header}
          <Container>
            { this.state.info ? (
              <Info>
                <CloseBtn><FontAwesomeIconStyled icon={faTimesCircle} title="Close" onClick={() => this.toggleInfo()} /></CloseBtn>
                Hei! Olen teettänyt KMedia.fi -työkalun lähinnä omaan käyttööni. Jos 
                siitä on iloa muillekin, niin olen ilahtunut! KMedia.fi:n tarkoitus on 
                tuoda vähemmän tunnettujen medioiden otsikoita yhteen, mahdollisimman 
                yksinkertaiseen näkymään.
                <InfoCredits>
                  <div>Ossi Tiihonen</div>
                  <a href="//ossitiihonen.com" target="_blank" rel="noopener noreferrer">www.ossitiihonen.com</a>
                </InfoCredits>
                <DashLine />
                Hi, I have produced this KMedia.fi -tool mainly to my personal use. If 
                it benefits you I am delighted! The purpose of KMedia.fi is simply to 
                put headlines of less known media (opposite to MSM) into same place.

                <InfoCredits>
                  <div>Ossi Tiihonen</div>
                  <a href="//ossitiihonen.com" target="_blank" rel="noopener noreferrer">www.ossitiihonen.com</a>
                </InfoCredits>
              </Info>
            ) : null }
            <RSS>
              {newsArray}
            </RSS>
          </Container>
        </Body>
      )
    }
    else if (!this.state.gridByDate && this.state.news.length > 0) {
      let newsArray = [];
      for (const newsSource of this.state.news) {
        const news = newsSource.url.map((item, index) => (
          <a href={item.link} target="_blank" key={item.link} rel="noopener noreferrer">  
            <NewsItem>
              <NewsTitle>{item.title}</NewsTitle>
              {/* <BottomBar>
                <DateText>{ this.formatDate(new Date(item.pubDate)) }</DateText>
              </BottomBar> */}
            </NewsItem>
          </a>
        ));
        const group = (
            <NewsSource key={newsSource.name}>
              <NewsSourceTitle>
                <a href={newsSource.link} target="_blank" rel="noopener noreferrer">{newsSource.name}</a>
              </NewsSourceTitle>
              {news}
            </NewsSource>
        );
        newsArray.push(group);
      }
      return (
        <Body>
          {header}
          <Container>
            <RSS>
              {newsArray}
            </RSS>
          </Container>
        </Body>
      )
    } else {
      return (
        <Body>
          {header}
          <Container>
              <Loading></Loading>
          </Container>
        </Body>
      )
    }
  }

}

export default RSSFeed